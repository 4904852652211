import { type InputHTMLAttributes } from "react";
import { cn } from "@/lib/utils.ts";

type CheckBaseInputProps = InputHTMLAttributes<HTMLInputElement> & {
  className?: string;
};

export function NewCheckbox({ className, ...props }: CheckBaseInputProps) {
  return <input type="checkbox" className={cn("check-base-input", className)} {...props} />;
}

export function NewRadio({ className, ...props }: CheckBaseInputProps) {
  return <input type="radio" className={cn("check-base-input", className)} {...props} />;
}
