import { TRepairRequestItem, TRepairStatus } from "@/types/repair.type.ts";
import { NavLink } from "react-router-dom";
import { buttonVariants } from "@/components/ui/button.tsx";
import { CheckIcon, ChevronLeft, Loader2, type LucideIcon, PackageOpenIcon, StarIcon, XIcon } from "lucide-react";
import { dict } from "@/dictionary";
import { cn } from "@/lib/utils.ts";
import { formatDateTime } from "@/lib/utils/date-time-format.ts";

export default function RepairRequestCard({
  status,
  date,
  time,
  images,
  services,
  description,
  id,
  isBuyer,
  user_full_name,
}: TRepairRequestItem) {
  return (
    <div className="p-4 bg-gray-scale-5 divide-y divide-white/10">
      <div className="flex flex-col gap-4 pb-4">
        <div className="flex items-center gap-2">
          <Status label={dict.repairs[status]} {...statusInfo[status]} />
          <NavLink to={id} className={buttonVariants({ variant: "secondary", size: "icon" })}>
            {({ isPending }) => (isPending ? <Loader2 className="animate-spin" /> : <ChevronLeft />)}
          </NavLink>
        </div>
        <div className="flex items-center gap-4">
          {user_full_name}
          {isBuyer && <span className="bg-white/5 text-xs text-primary p-2">{dict.repairs.validBuyer}</span>}
          <span className="ms-auto">{formatDateTime({ date, time })}</span>
        </div>
      </div>
      <div className="flex gap-4 pt-4">
        <div className="flex items-center gap-4">
          {images.map((imgSrc, index) => (
            <img key={index} className="w-[58px] h-[58px] aspect-square bg-white" src={imgSrc} alt="repair image" />
          ))}
        </div>
        <div className="flex flex-col gap-2">
          <div className="flex gap-4">
            {services.map((service, index) => (
              <span key={index} className="[&:not(:last-of-type)]:border-e border-white/10 [&:not(:last-of-type)]:pe-4">
                {service.fa}
              </span>
            ))}
          </div>
          <p>{description}</p>
        </div>
      </div>
    </div>
  );
}

const statusInfo: { [key in TRepairStatus]: { icon: LucideIcon; color: TStatusColor } } = {
  new: {
    color: "primary",
    icon: StarIcon,
  },
  done: {
    icon: CheckIcon,
    color: "success",
  },
  visited: { color: "gray-scale-3", icon: PackageOpenIcon },
  canceled: { icon: XIcon, color: "destructive" },
};

type TStatusColor = "primary" | "gray-scale-3" | "success" | "destructive";
type StatusProps = { icon?: LucideIcon; label: string; color: TStatusColor };

const statusColorClass: { [key in TStatusColor]: string } = {
  primary: "border-primary text-primary",
  "gray-scale-3": "border-gray-scale-3 text-gray-scale-3",
  success: "border-success text-success",
  destructive: "border-destructive text-destructive",
}; // Use JIT later

function Status({ icon: Icon, label, color }: StatusProps) {
  return (
    <div className={cn("flex items-center gap-1 text-sm p-2.5 flex-1 bg-white/5 border-r-2", statusColorClass[color])}>
      {Icon ? <Icon size={16} /> : null}
      <span>{label}</span>
    </div>
  );
}
