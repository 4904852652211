export default {
  brandName: "tenzu shop",
  home: "خانه",
  orders: "سفارشات",
  products: "مدیریت محصولات",
  users: "مدیریت کاربران",
  siteSettings: "مدیریت وبسایت",
  admins: "مدیریت ادمین‌ها",
  comments: "نظرات کاربران",
  repairs: "تعمیرات",
  logs: "لیست لاگ‌ها",
};
