import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table.tsx";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";
import { useEffect } from "react";
import { ProductAPI } from "@/api";
import { TInspectionListData, TUpdateCategoryItemArgs } from "@/types/used-product.type.ts";
import { NewCheckbox } from "@/components/ui/check-base-inputs.tsx";
import { Input } from "../ui/input";
import { TabsContent } from "@/components/ui/tabs.tsx";

type HealthTabContentProps = { id?: string };

export default function HealthTabContent({ id }: HealthTabContentProps) {
  const { setValue, watch, getValues } = useFormContext();
  const healthData: TInspectionListData = watch("health");
  const { t } = useTranslation();

  function updateCategoryItem({ type, index, mutate }: TUpdateCategoryItemArgs) {
    const health: TInspectionListData = getValues("health");
    mutate(health[type].items[index]);
    setValue("health", health);
  }

  async function setInitialHealthData() {
    setValue("health", await ProductAPI.getInspectionList(id));
  }

  useEffect(() => {
    setInitialHealthData();
    // eslint-disable-next-line
  }, []);

  return (
    <TabsContent value="health">
      <div className="bg-gray-scale-5 p-4 max-h-[35rem] overflow-y-auto">
        <Table>
          <TableHeader>
            <TableRow>
              {tableHeadItems.map(({ title, className }) => (
                <TableHead key={title} className={className}>
                  {t(title)}
                </TableHead>
              ))}
            </TableRow>
          </TableHeader>
          <TableBody>
            {Object.values(healthData || {}).map(({ id, items, type }) =>
              items.map(({ title, status, rating, value, note }, index) => (
                <TableRow key={id + index}>
                  <TableCell className="text-gray-scale-3">
                    <NewCheckbox
                      checked={Boolean(status)}
                      onChange={({ target: { checked } }) =>
                        updateCategoryItem({
                          type,
                          index,
                          mutate: (categoryItem) => (categoryItem.status = checked),
                        })
                      }
                    />
                  </TableCell>
                  <TableCell className="text-gray-scale-3">{title.fa}</TableCell>
                  <TableCell>
                    <Input
                      placeholder={t("products.healthCheck.actualValue")}
                      disabled={!status}
                      type="number"
                      value={rating || ""}
                      onChange={({ target: { value } }) =>
                        updateCategoryItem({
                          type,
                          index,
                          mutate: (categoryItem) => (categoryItem.rating = +value),
                        })
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <Input
                      placeholder={t("products.healthCheck.value")}
                      disabled={!status}
                      value={value || ""}
                      onChange={({ target: { value } }) =>
                        updateCategoryItem({
                          type,
                          index,
                          mutate: (categoryItem) => (categoryItem.value = value),
                        })
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <Input
                      placeholder={t("products.healthCheck.note")}
                      disabled={!status}
                      value={note || ""}
                      onChange={({ target: { value } }) =>
                        updateCategoryItem({
                          type,
                          index,
                          mutate: (categoryItem) => (categoryItem.note = value),
                        })
                      }
                    />
                  </TableCell>
                </TableRow>
              )),
            )}
          </TableBody>
        </Table>
      </div>
    </TabsContent>
  );
}

const tableHeadItems = [
  { title: "products.healthCheck.status" },
  { title: "products.healthCheck.properties" },
  { title: "products.healthCheck.actualValue" },
  { title: "products.healthCheck.value" },
  { title: "products.healthCheck.note", className: "w-[450px]" },
];
