import { cn } from "@/lib/utils.ts";
import Calendar from "@/components/ui/calendar.tsx";
import { type ReactNode } from "react";
import DateObject from "react-date-object";
import { TRepairDateTime } from "@/types/repair.type.ts";
import { formatTime } from "@/lib/utils/date-time-format.ts";
import { dict } from "@/dictionary";

const minDate = new DateObject();
const maxDate = new DateObject(minDate).add(10, "days");

type DateTimeProps = {
  times: TRepairDateTime[];
  renderTimeStatus: (dateTime: TRepairDateTime) => ReactNode;
  isPending?: boolean;
  date: DateObject;
  onDateChange: (date: DateObject) => void;
};

export function DateTime({ times, renderTimeStatus, isPending, date, onDateChange }: DateTimeProps) {
  return (
    <div className={cn("grid grid-cols-1 sm:grid-cols-2 bg-white/5", isPending && "skeleton pointer-events-none")}>
      <Calendar value={date} onChange={onDateChange} minDate={minDate} maxDate={maxDate} />
      <div className="p-3 sm:p-5 flex-1 max-sm:border-t sm:border-s">
        <header className="flex justify-between items-center pb-2 border-b text-sm max-sm:font-medium sm:text-xl">
          <span>{dict.repairs.time}</span>
          <span>{dict.repairs.status}</span>
        </header>
        <main>
          {times.map((dateTime) => {
            const { status, date, time } = dateTime;
            const isAvailable = status === "available";
            return (
              <div key={date + time} className="flex items-center justify-between gap-5 my-3 last-of-type:mb-0">
                <div className={cn("flex items-center gap-3", isAvailable ? "cursor-pointer" : "cursor-not-allowed")}>
                  <span className={cn(!isAvailable && "text-gray-scale-4")}>{formatTime(time)}</span>
                </div>
                {renderTimeStatus(dateTime)}
              </div>
            );
          })}
        </main>
      </div>
    </div>
  );
}
