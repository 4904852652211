import DateObject from "react-date-object";
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";
import gregorian from "react-date-object/calendars/gregorian";

type TFormatDateOptions = { format?: string; separator?: string };

export function formatNumericDate(
  date: string | Date,
  { format = "YYYY - MM - DD", separator = " - " }: TFormatDateOptions = {},
) {
  const configs = { date: new Date(date), locale: persian_fa, calendar: persian };
  return new DateObject(configs).format(format.split(separator).reverse().join(separator));
}

export function formatAlphabeticDay(date: string | Date, format: string) {
  const configs = {
    date: new Date(date),
    locale: persian_fa,
    calendar: persian,
  };

  return new DateObject(configs).format(format);
}

export function formatDateTime({ date, time }: Partial<Record<"date" | "time", string>>) {
  if (date) {
    return [formatAlphabeticDay(date, "dddd"), formatNumericDate(date), formatTime(time)].filter(Boolean).join("، ");
  }
}

export function formatForAPI(date: DateObject) {
  const formattedDate = new DateObject({
    date,
    format: "YYYY-MM-DD",
    calendar: gregorian,
  }).format("YYYY-MM-DD");
  return convertFaNumsToEn(formattedDate);
}

function convertFaNumsToEn(str: string) {
  const faNumToEn = {
    "۰": "0",
    "۱": "1",
    "۲": "2",
    "۳": "3",
    "۴": "4",
    "۵": "5",
    "۶": "6",
    "۷": "7",
    "۸": "8",
    "۹": "9",
  };

  return str.replace(/[۰-۹]/g, (match) => faNumToEn[match as keyof typeof faNumToEn]);
}

export function formatTime(time?: string) {
  if (time) {
    return time.padStart(2, "0").padEnd(5, ":00");
  }
}
