export { default as fa } from "./fa";

export const dict = {
  common: {
    save: "ذخیره",
    edit: "ویرایش",
    fa: "فارسی",
    en: "انگلیسی",
    add: "افزودن",
  },
  repairs: {
    title: "تعمیرات",
    requestsTitle: "درخواستها",
    requestDetailTitle: "جزئیات درخواست",
    services: "سرویس ها",
    activeDays: "روزهای فعال",
    service: "سرویس",
    duration: "مدت زمان",
    price: "قیمت",
    note: "یادداشت",
    repair: "تعمیرات",
    adjustment: "تنظیم",
    part_replacement: "تعویض قطعه",
    heavy_repair: "تعمیر سنگین",
    paint_jobs: "امور رنگ",
    free: "رایگان",
    T: "ت",
    days: "روز",
    date: "تاریخ",
    day: "روز",
    time: "زمان",
    status: "وضعیت",
    available: "در دسترس",
    closed: "بسته",
    busy: "رزرو شده",
    dateDuplicateErr: "این روز هم اکنون موجود است",
    all: "همه",
    new: "جدید",
    visited: "بررسی شده",
    done: "انجام شده",
    canceled: "لغو شده",
    changeStatus: "تغییر وضعیت",
    images: "تصاویر",
    serviceKind: "نوع سرویس",
    instrumentModel: "مدل ساز",
    problem: "مشکل",
    validBuyer: "خریدار معتبر",
  },
};
