import Editor from "@/components/editor.tsx";
import { Button } from "@/components/ui/button.tsx";
import { useForm } from "react-hook-form";
import type { TRepairContent } from "@/types/repair.type.ts";
import { RepairAPI } from "@/api";
import { toast } from "@/components/ui/use-toast.ts";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs.tsx";
import { dict } from "@/dictionary";
import type { TLang } from "@/types/general.ts";

type ContentFormProps = { defaultValues: TRepairContent };

export default function ContentsForm({ defaultValues }: ContentFormProps) {
  const {
    watch,
    setValue,
    getValues,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm({
    defaultValues,
  });

  async function submit(content: TRepairContent) {
    const { message } = await RepairAPI.setContent(content);
    toast({ description: message.fa });
  }

  return (
    <form onSubmit={handleSubmit(submit)}>
      <Tabs defaultValue="fa" className="flex flex-col gap-1">
        <div className="flex justify-between">
          <TabsList>
            <TabsTrigger value="fa">{dict.common.fa}</TabsTrigger>
            <TabsTrigger value="en">{dict.common.en}</TabsTrigger>
          </TabsList>
          <Button type="submit" loading={isSubmitting}>
            {dict.common.save}
          </Button>
        </div>
        <TabsContent value="fa">{renderFormEditors("fa")}</TabsContent>
        <TabsContent value="en">{renderFormEditors("en")}</TabsContent>
      </Tabs>
    </form>
  );

  function renderFormEditors(lang: TLang) {
    return (
      <div className="flex flex-col gap-3">
        {(Object.keys(defaultValues) as (keyof TRepairContent)[]).map((name) => (
          <Editor
            key={name}
            data={watch(name)[lang]}
            onChange={(value) => setValue(name, { ...getValues(name), [lang]: value })}
          />
        ))}
      </div>
    );
  }
}
