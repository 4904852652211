import { API } from "@/lib/axios";
import { TMutationResponse, TPaginatedList, TTranslatedText } from "@/types/general";
import { TProduct, TProductCount, TProductDetailed, TProductNew, TProductQuery } from "@/types/product";
import { AxiosResponse } from "axios";
import type { TInspectionListData, TSetInspectionBody } from "@/types/used-product.type.ts";
import { prepareInspectionBody } from "@/lib/utils/inspection-body.ts";

const ENTITY = "/product";

export const getList = (query: TProductQuery): Promise<AxiosResponse<{ data: TPaginatedList<TProduct> }>> =>
  API.post(`${ENTITY}/list`, query);

export const getCounts = (): Promise<AxiosResponse<{ data: { items: TProductCount[] } }>> => API.get(`${ENTITY}/count`);

export const updatePriceById = (
  id: string,
  data: Partial<{ price: number; is_competitive: boolean; call_to_buy: boolean; total_count: number }>,
): Promise<AxiosResponse<{ data: { message: TTranslatedText } }>> =>
  API.patch(`${ENTITY}/update-price`, { products: [{ id, ...data }] });

export const getById = async (id: string): Promise<AxiosResponse<{ data: TProductDetailed }>> =>
  API.post(`${ENTITY}/detail`, { id });

export async function create(body: TProductNew): Promise<string> {
  const { data } = await API.post(ENTITY, body);
  return data.data.id;
}

export const draft = async (productId: string): Promise<AxiosResponse<{ data: { message: TTranslatedText } }>> =>
  API.post(`${ENTITY}/draft`, { product_id: productId });

export async function publish(productId: string): Promise<TMutationResponse> {
  const { data } = await API.post(`${ENTITY}/publish`, { product_id: productId });
  return data.data;
}

export const update = async (
  productId: string,
  data: TProductNew,
): Promise<AxiosResponse<{ data: { message: TTranslatedText } }>> => API.patch(`${ENTITY}`, { id: productId, ...data });

export const deleteById = async (productId: string): Promise<AxiosResponse<{ data: { message: TTranslatedText } }>> =>
  API.delete(`${ENTITY}/${productId}`);

export async function getInspectionList(id?: string): Promise<TInspectionListData> {
  const { data } = await API.get(ENTITY + (id ? `/inspection/${id}` : "/inspection-list"));
  return id ? data.data.items : data.data;
}

export async function setInspection(body: TSetInspectionBody): Promise<TMutationResponse> {
  const { data } = await API.post(`${ENTITY}/set-inspection`, prepareInspectionBody(body));
  return data.data;
}
