import { TRepairDateTime } from "@/types/repair.type";
import { dict } from "@/dictionary";
import { Dialog, DialogContent, DialogTrigger } from "@/components/ui/dialog.tsx";
import { buttonVariants } from "@/components/ui/button.tsx";
import { DateTime } from "@/pages/repair/date-time-section/date-time.tsx";
import EditRepairTimes from "@/pages/repair/date-time-section/edit-time.tsx";
import { useState } from "react";
import DateObject from "react-date-object";
import { RepairAPI } from "@/api";
import { formatForAPI } from "@/lib/utils/date-time-format.ts";

type RepairDatesTable = {
  initialTimes: TRepairDateTime[];
};

export default function RepairDateTimeSection({ initialTimes }: RepairDatesTable) {
  const [date, setDate] = useState(new DateObject());
  const [times, setTimes] = useState(initialTimes);
  const [isPending, setIsPending] = useState(false);

  function handleDateChange(newDate: DateObject) {
    setDate(newDate);
    formatForAPI(date) !== formatForAPI(newDate) && updateTimes(newDate);
  }

  async function updateTimes(newDate: DateObject) {
    setIsPending(true);
    setTimes(await RepairAPI.getDateTimes(formatForAPI(newDate)));
    setIsPending(false);
  }

  return (
    <div className="flex flex-col gap-5">
      <div className="flex items-center justify-between">
        <h3>{dict.repairs.activeDays}</h3>
        <Dialog>
          <DialogTrigger className={buttonVariants()}>{dict.common.edit}</DialogTrigger>
          <DialogContent className="max-w-4xl">
            <EditRepairTimes
              times={times}
              isPending={isPending}
              date={date}
              onTimesUpdate={() => updateTimes(date)}
              onDateChange={handleDateChange}
            />
          </DialogContent>
        </Dialog>
      </div>
      <DateTime
        times={times}
        isPending={isPending}
        renderTimeStatus={renderReadOnlyTimeStatus}
        date={date}
        onDateChange={handleDateChange}
      />
    </div>
  );
}

function renderReadOnlyTimeStatus({ status }: TRepairDateTime) {
  return (
    <span
      className={
        {
          available: "text-success",
          closed: "text-gray-scale-4",
          busy: "text-destructive",
        }[status]
      }
    >
      {dict.repairs[status]}
    </span>
  );
}
