import { useLoaderData } from "react-router-dom";
import { TRepairRequestItem } from "@/types/repair.type.ts";
import { dict } from "@/dictionary";
import Page from "@/components/page.tsx";
import { useTranslation } from "react-i18next";
import { Label } from "@/components/ui/label.tsx";
import { Input } from "@/components/ui/input.tsx";
import { Textarea } from "@/components/ui/textarea.tsx";
import { formatDateTime } from "@/lib/utils/date-time-format.ts";
import { UpdateRepairRequestStatus } from "@/pages/repair/requests/request-detail/update-status.tsx";

const pageTitle = dict.repairs.requestDetailTitle;

export default function RepairRequestDetail() {
  const {
    id,
    status,
    user_full_name,
    user_phone_number,
    images,
    services,
    instrument_model,
    problem_content,
    description,
    date,
    time,
  } = useLoaderData() as TRepairRequestItem;
  const { t } = useTranslation();

  return (
    <Page
      breadcrumbs={[
        { label: t("sidebar.home"), link: "/" },
        { label: dict.repairs.title, link: "/repair" },
        {
          label: dict.repairs.requestsTitle,
          link: "/repair/requests?" + new URLSearchParams({ status: "all", page: "1", limit: "10" }),
        },
        { label: pageTitle },
      ]}
      title={pageTitle}
    >
      <main className="flex flex-col gap-10 bg-white/5 p-5 max-w-[688px]">
        <UpdateRepairRequestStatus id={id} status={status} />
        <div className="flex flex-col gap-5">
          {user_full_name || user_phone_number ? (
            <div className="flex text-2xl font-semibold">
              {user_full_name ? <p className="pe-5 me-5 border-e border-white/10">{user_full_name}</p> : null}
              {user_phone_number ? <p>{user_phone_number}</p> : null}
            </div>
          ) : null}
          <div>
            <Label className="block mb-2">{dict.repairs.images}</Label>
            <div className="flex gap-2.5">
              {images.map((imageSrc, index) => (
                <img
                  key={index}
                  src={imageSrc}
                  alt={"Repair Image" + index}
                  className="bg-white w-[100px] h-[100px] object-cover aspect-square"
                />
              ))}
            </div>
          </div>
          <ReadOnlyInput label={dict.repairs.serviceKind} value={services.map(({ fa }) => fa).join(" - ")} />
          <ReadOnlyInput label={dict.repairs.instrumentModel} value={instrument_model} />
          <ReadOnlyInput label={dict.repairs.problem} value={problem_content} />
          <Textarea readOnly defaultValue={description} />
          <div>
            <Label className="block mb-2">{dict.repairs.date}</Label>
            <span className="text-xl">{formatDateTime({ date, time })}</span>
          </div>
        </div>
      </main>
    </Page>
  );
}

function ReadOnlyInput({ label, value }: { label: string; value?: string }) {
  return value ? (
    <div>
      <Label className="block mb-2">{label}</Label>
      <Input readOnly defaultValue={value} />
    </div>
  ) : null;
}
