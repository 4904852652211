import { BrandAPI, CategoryAPI, ColorAPI, ModelAPI, ProductAPI, SubcategoryAPI, TagAPI } from "@/api";
import { LoaderFunctionArgs } from "react-router-dom";

export default async function loader({ params }: LoaderFunctionArgs) {
  const { id } = params as { id: string };
  if (!id) return null;

  return Promise.all([
    ProductAPI.getById(id),
    BrandAPI.getList({ page: 1, limit: 500 }),
    CategoryAPI.getList(),
    TagAPI.getList({ page: 1, limit: 500 }),
    ColorAPI.getList(),
  ])
    .then(([product, brands, categories, tags, colors]) => {
      return Promise.all([
        ModelAPI.getList({
          brand_id: product.data.data.brand.id,
        }),
        SubcategoryAPI.getListByCategoryId(product.data.data.category.id),
      ])
        .then(([models, subcategories]) => {
          return {
            product: {
              ...product.data.data,
            },
            brands: brands.data.data.items,
            models: models.data.data.items,
            categories: categories.data.data.items,
            subcategories: subcategories.data.data.items,
            tags: tags.data.data.items,
            colors: colors.data.data.items,
          };
        })
        .catch((err) => err);
    })
    .catch((err) => {
      console.log(err);
      return null;
    });
}
