import { Label } from "@/components/ui/label.tsx";
import { dict } from "@/dictionary";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select.tsx";
import { TRepairStatus } from "@/types/repair.type.ts";
import { toast } from "@/components/ui/use-toast.ts";
import { useState } from "react";
import { RepairAPI } from "@/api";
import { Loader2 } from "lucide-react";

const statuses: TRepairStatus[] = ["new", "canceled", "visited", "done"];

type UpdateStatusProps = { id: string; status: TRepairStatus };

export function UpdateRepairRequestStatus({ id, status }: UpdateStatusProps) {
  const [currentStatus, setCurrentStatus] = useState(status);
  const [updating, setUpdating] = useState(false);

  async function updateCurrentStatus(status: TRepairStatus) {
    setUpdating(true);
    const { message } = await RepairAPI.updateRequests({ id, status });
    setUpdating(false);
    toast({ description: message.fa });
    setCurrentStatus(status);
  }

  return (
    <div>
      <Label className="block mb-2">{dict.repairs.changeStatus}</Label>
      <Select value={currentStatus} onValueChange={(status) => updateCurrentStatus(status as TRepairStatus)}>
        <SelectTrigger>{updating ? <Loader2 className="animate-spin" size={16} /> : <SelectValue />}</SelectTrigger>
        <SelectContent>
          {statuses.map((status) => (
            <SelectItem key={status} value={status}>
              {dict.repairs[status]}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    </div>
  );
}
