import { NavLink, useLoaderData } from "react-router-dom";
import ContentsForm from "@/pages/repair/content-form.tsx";
import type { TRepairLoaderData } from "@/types/repair.type.ts";
import Page from "@/components/page.tsx";
import { dict } from "@/dictionary";
import { useTranslation } from "react-i18next";
import { buttonVariants } from "@/components/ui/button.tsx";
import { Loader2 } from "lucide-react";
import ServicesTable from "@/pages/repair/services-table.tsx";
import RepairDateTimeSection from "@/pages/repair/date-time-section";

export default function Repair() {
  const { content, services, times } = useLoaderData() as TRepairLoaderData;
  const { t } = useTranslation();

  return (
    <Page
      breadcrumbs={[{ label: t("sidebar.home"), link: "/" }, { label: pageTitle }]}
      title={pageTitle}
      action={linkToRequests}
    >
      <main className="flex flex-col gap-10 bg-white/5 p-10">
        <ContentsForm defaultValues={content} />
        <div className="flex flex-col gap-5">
          <h3>{dict.repairs.services}</h3>
          <ServicesTable services={services} />
        </div>
        <RepairDateTimeSection initialTimes={times} />
      </main>
    </Page>
  );
}

const pageTitle = dict.repairs.title;
const linkToRequests = (
  <NavLink
    to={"requests?" + new URLSearchParams({ status: "all", page: "1", limit: "10" })}
    className={buttonVariants({ variant: "secondary", className: "w-24" })}
  >
    {({ isPending }) =>
      isPending ? (
        <Loader2 className="animate-spin" />
      ) : (
        <div className="flex items-center gap-1">{dict.repairs.requestsTitle}</div>
      )
    }
  </NavLink>
);
