import { createContext, type ReactNode, useContext } from "react";
import { cn } from "@/lib/utils.ts";

// eslint-disable-next-line
const TabsContext = createContext<any>(undefined);

type TabsProps<T> = {
  value: T;
  onChange: (value: T) => void;
  children: ReactNode;
  className?: string;
};

export function StickyTabs<T extends string>({ value, onChange, children, className }: TabsProps<T>) {
  return (
    <TabsContext.Provider value={{ activeValue: value, setActiveValue: onChange }}>
      <div role="tabs" className={cn("flex", className)}>
        {children}
      </div>
    </TabsContext.Provider>
  );
}

type TabProps<T> = {
  label: string;
  value: T;
  className?: string;
};

export function StickyTab<T>({ label, value, className }: TabProps<T>) {
  const { activeValue, setActiveValue } = useContext(TabsContext);

  return (
    <span
      role="tab"
      className={cn(
        "select-none cursor-pointer rtl:font-dana ltr:font-din ltr:capitalize text-sm md:text-3xl md:rtl:text-2xl md:leading-none",
        "px-4 md:px-10 py-2 md:py-3.5 flex items-center justify-center border-2 border-b-0 border-transparent",
        activeValue === value
          ? "bg-[#1313130d] dark:bg-white/5 text-gray-scale-6 dark:text-gray-scale-1"
          : "bg-white-opacity-2 text-gray-scale-4 border-[#1313130d] dark:border-white/5",
        className,
      )}
      onClick={() => setActiveValue(value)}
    >
      {label}
    </span>
  );
}
