import { API } from "@/lib/axios.ts";
import {
  TRepairContent,
  TRepairDateTime,
  TRepairRequestItem,
  TRepairRequestsData,
  TRepairRequestsParams,
  TRepairServices,
  TStatusCount,
  TUpdateRequestBody,
} from "@/types/repair.type.ts";
import { TMutationResponse } from "@/types/general.ts";

const ENTITY = "/repair";


export async function getContent(): Promise<TRepairContent> {
  const { data } = await API.get(ENTITY + "/content/get");
  return data.data;
}

export async function setContent(content: TRepairContent): Promise<TMutationResponse> {
  const { data } = await API.patch(ENTITY + "/content/set", content);
  return data.data;
}

export async function getServices(): Promise<TRepairServices> {
  const { data } = await API.get(ENTITY + "/service/list");
  return data.data;
}

export async function getDateTimes(date: string): Promise<TRepairDateTime[]> {
  const { data } = await API.get(ENTITY + "/date/" + date);
  return data.data.items;
}

export async function setDates(days: TRepairDateTime[]): Promise<TMutationResponse> {
  const { data } = await API.patch(ENTITY + "/date", { days });
  return data.data;
}

export async function getRequests(params: TRepairRequestsParams): Promise<TRepairRequestsData> {
  const { data } = await API.get(ENTITY + "/request", { params });
  return data.data;
}

export async function updateRequests(body: TUpdateRequestBody): Promise<TMutationResponse> {
  const { data } = await API.patch(ENTITY + "/request", body);
  return data.data;
}

export async function getRequestItem(id: string): Promise<TRepairRequestItem> {
  const { data } = await API.get(ENTITY + "/request/" + id);
  return data.data;
}

export async function getStatusCount(): Promise<TStatusCount[]> {
  const { data } = await API.get(ENTITY + "/status-count");
  return data.data.items;
}
