import { Calendar as ReactMultiCalendar, type CalendarProps } from "react-multi-date-picker";
import persian_fa from "react-date-object/locales/persian_fa";
import persian from "react-date-object/calendars/persian";

const weekDays = ["ش", "ی", "د", "س", "چ", "پ", "ج"];

export default function Calendar(props: CalendarProps) {
  return (
    <div className="h-[290px] sm:h-[334px]">
      <ReactMultiCalendar
        locale={persian_fa}
        calendar={persian}
        format="DD - MM - YYYY"
        className="calendar"
        monthYearSeparator="، "
        weekDays={weekDays}
        disableMonthPicker
        disableYearPicker
        {...props}
      />
    </div>
  );
}
