import mobile from "./mobile";
import common from "./common";
import login from "./login";
import sidebar from "./sidebar";
import orders from "./orders";
import ordersDetail from "./orders-detail";
import productsManagement from "./products-management";
import categories from "./categories";
import subcategories from "./subcategories";
import productsTags from "./products-tags";
import brands from "./brands";
import prices from "./prices";
import products from "./products";
import models from "./models";
import users from "./users";
import website from "./website";
import aboutUs from "./about-us";
import policy from "./policy";
import carousel from "./carousel";
import faq from "./faq";
import admins from "./admins";
import sliders from "./sliders";
import comments from "./comments";
import logs from "./logs";
import usedProducts from "./used-products"; 

export default {
  translation: {
    mobile,
    common,
    login,
    sidebar,
    orders,
    ordersDetail,
    productsManagement,
    categories,
    subcategories,
    productsTags,
    brands,
    prices,
    products,
    models,
    users,
    website,
    aboutUs,
    policy,
    carousel,
    faq,
    admins,
    sliders,
    comments,
    logs,
    usedProducts,
  },
};
