import { type RouteObject } from "react-router-dom";
import Repair from "@/pages/repair";
import loadRepairsData from "@/pages/repair/loader.ts";
import loadRepairRequests from "@/pages/repair/requests/loader.ts";
import RepairRequests from "@/pages/repair/requests";
import RepairRequestDetail from "@/pages/repair/requests/request-detail";
import loadRepairRequestDetail from "@/pages/repair/requests/request-detail/loader.ts";

export const repairRouteObjects: RouteObject[] = [
  {
    path: "repair",
    element: <Repair />,
    loader: loadRepairsData,
  },
  {
    path: "repair/requests",
    element: <RepairRequests />,
    loader: loadRepairRequests,
  },
  {
    path: "repair/requests/:id",
    element: <RepairRequestDetail />,
    loader: loadRepairRequestDetail,
  },
];
