import { RepairAPI } from "@/api";
import type { TRepairLoaderData } from "@/types/repair.type.ts";
import DateObject from "react-date-object";
import { formatForAPI } from "@/lib/utils/date-time-format.ts";

export default async function loadRepairsData(): Promise<TRepairLoaderData> {
  const [content, services, times] = await Promise.all([
    RepairAPI.getContent(),
    RepairAPI.getServices(),
    RepairAPI.getDateTimes(formatForAPI(new DateObject())),
  ]);
  return {
    content,
    services,
    times,
  };
}
