import { ModelAPI, ProductAPI, SubcategoryAPI } from "@/api";
import ProductInfo from "@/components/svg/product-icons/0-info";
import ProductBody from "@/components/svg/product-icons/1-body";
import ProductNeck from "@/components/svg/product-icons/2-neck";
import ProductDevice from "@/components/svg/product-icons/3-device";
import ProductElectronics from "@/components/svg/product-icons/4-electronics";
import ProductMore from "@/components/svg/product-icons/5-more";
import { ADMIN_PERMISSIONS } from "@/consts/admin.const";
import { PRODUCT_PROPERTIES_ICONS } from "@/consts/product.const";
import { cn, hasPermissions } from "@/lib/utils";
import { TBrand } from "@/types/brand";
import { TCategory } from "@/types/category";
import { TColor } from "@/types/color";
import { TModel } from "@/types/model";
import { TProductDetailed, TProductIcon } from "@/types/product";
import { TTag } from "@/types/tag";
import { addCommas } from "@persian-tools/persian-tools";
import { Pencil, Trash2 } from "lucide-react";
import React from "react";
import { FormProvider, type SubmitErrorHandler, useForm } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import Editor from "../editor";
import MultiSelect from "../multi-select";
import { useAuth } from "../providers/auth-provider";
import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "../ui/alert-dialog";
import { Button } from "../ui/button";
import { Checkbox } from "../ui/checkbox";
import { Dialog, DialogClose, DialogContent, DialogHeader, DialogTitle } from "../ui/dialog";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "../ui/form";
import { Input } from "../ui/input";
import { Label } from "../ui/label";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../ui/select";
import { Switch } from "../ui/switch";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../ui/tabs";
import { toast } from "../ui/use-toast";
import ColorPicker from "./color-picker";
import ImageGallery from "./image-gallery";
import type { TProductForm } from "./schema";
import HealthTabContent from "@/components/product-form/health-tab-content.tsx";

type TProps = {
  product?: TProductDetailed;
  brands: TBrand[];
  models: TModel[];
  colors: TColor[];
  categories: TCategory[];
  subcategories: TCategory[];
  tags: TTag[];
  onPublish: (values: TProductForm) => string[] | (() => Promise<void>);
  onDraft: (values: TProductForm) => string[] | (() => Promise<void>);
  onValidate: (values: TProductForm) => string[] | null;
};

type TIconEl = {
  id: TProductIcon;
  selected: boolean;
  el: JSX.Element;
};

type TPropertyForm = {
  parentId: number | null;
  fa: string;
  en: string;
  desc_fa: string;
  desc_en: string;
  index: null | number;
  dialogTitle: string;
};

type TFeatureItem = {
  fa: string;
  en: string;
  index: null | number;
  dialogTitle: string;
};

export default function ProductForm(props: TProps) {
  const { product, brands, models, colors, categories, subcategories, tags, onDraft, onPublish, onValidate } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const params = useParams() as { productId?: string; id: string };
  const { profile } = useAuth();

  const getPropertyItemDefaultValue = () => ({
    fa: "",
    en: "",
    desc_fa: "",
    desc_en: "",
    parentId: null,
    index: null,
    dialogTitle: t("products.addNewSpec"),
  });

  const getFeatureItemDefaultValue = () => ({
    fa: "",
    en: "",
    index: null,
    dialogTitle: t("products.addNewFeature"),
  });

  const [subcategroyList, setSubcategroyList] = React.useState(subcategories);
  const [modelsList, setModelsList] = React.useState(models);
  const [newGroupInfo, setNewGroupInfo] = React.useState({ fa: "", en: "", icon: "" });
  const [propertyItem, setPropertyItem] = React.useState<TPropertyForm>(getPropertyItemDefaultValue());
  const [featureItem, setFeatureItem] = React.useState<TFeatureItem>(getFeatureItemDefaultValue());
  const [openDialog, setOpenDialog] = React.useState(false);
  const [dialogContentEl, setDialogContentEl] = React.useState<"newGroup" | "newItem" | "newFeature" | null>(null);
  const [activeErrors, setActiveErrors] = React.useState<string[]>([]);
  const [loading, setLoading] = React.useState({ publish: false, draft: false });
  const [deleting, setDeleting] = React.useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const [hasWatermark, setHasWatermark] = React.useState(false);
  const icons = [
    {
      id: PRODUCT_PROPERTIES_ICONS.INFO,
      selected: false,
      el: <ProductInfo />,
    },
    {
      id: PRODUCT_PROPERTIES_ICONS.BODY,
      selected: false,
      el: <ProductBody />,
    },
    {
      id: PRODUCT_PROPERTIES_ICONS.NECK,
      selected: false,
      el: <ProductNeck />,
    },
    {
      id: PRODUCT_PROPERTIES_ICONS.DEVICE,
      selected: false,
      el: <ProductDevice />,
    },
    {
      id: PRODUCT_PROPERTIES_ICONS.ELECTRONICS,
      selected: false,
      el: <ProductElectronics />,
    },
    {
      id: PRODUCT_PROPERTIES_ICONS.MORE,
      selected: false,
      el: <ProductMore />,
    },
  ];

  const getDefaultValues = (): TProductForm => {
    if (!product) {
      return {
        name: "",
        brand: "",
        model: "",
        category: "",
        subcategory: "",
        seo_name_en: "",
        seo_name_fa: "",
        tags: [],
        description: {
          fa: "",
          en: "",
        },
        color: {
          base_color: "",
          has_color: false,
          product_color_name_en: "",
          product_color_name_fa: "",
        },
        price: {
          price: "0",
          call_to_buy: false,
          is_used: false,
          sale: {
            is_on_sale: false,
            off_percent: "0",
          },
        },
        images: [],
        availability: {
          total_count: "0",
        },
        features: [],
        properties: [],
        health: undefined,
      };
    }

    return {
      name: product.name,
      brand: product.brand.id,
      model: product.model.id,
      category: product.category.id,
      subcategory: product.subcategory.id,
      seo_name_en: product.seo_name.en,
      seo_name_fa: product.seo_name.fa,
      tags: product.tags.map((tag) => tag.id),
      description: product.description,
      color: {
        base_color: product.color.base_color.id ?? "",
        has_color: product.color.has_color ?? false,
        product_color_name_en: product.color.product_color_name_en ?? "",
        product_color_name_fa: product.color.product_color_name_fa ?? "",
      },
      price: {
        price: String(product.price.price),
        call_to_buy: product.price.call_to_buy,
        is_used: product.price.is_used,
        sale: {
          is_on_sale: product.price.sale.is_on_sale,
          off_percent: product.price.sale.off_percent.toString(),
        },
      },
      images: product.images,
      availability: {
        total_count: product.availability.total_count.toString(),
      },
      features: product.features,
      properties: product.properties.map((item) => ({ ...item, icon: item.icon.toString() })),
    };
  };

  const getAvailableGroups = (productProperties: TProductForm["properties"]): TIconEl[] => {
    const productProps: TProductIcon[] = productProperties?.map((prop) => +prop.icon as TProductIcon) ?? [];
    return icons.filter((icon) => {
      return !productProps.includes(icon.id);
    });
  };

  const form = useForm<TProductForm>({
    defaultValues: getDefaultValues(),
  });

  // Pointless
  const onSubmit = () => {
    setActiveErrors([]);
  };
  // Pointless
  const onError: SubmitErrorHandler<TProductForm> = (errors) => {
    setActiveErrors(Object.keys(errors).map((key) => t(`products.errors.${key}`)));
  };

  const onEditFeature = () => {
    const currentFeatures = form.getValues("features") ?? [];
    if (featureItem.index !== null) {
      currentFeatures[featureItem.index] = { fa: featureItem.fa, en: featureItem.en };
    } else {
      currentFeatures.push({ fa: featureItem.fa, en: featureItem.en });
    }
    form.setValue("features", currentFeatures);
    setFeatureItem({ fa: "", en: "", dialogTitle: "", index: null });
    setOpenDialog(false);
  };

  const onEditPropertyItem = () => {
    const currentProps = form.getValues("properties") ?? [];
    if (propertyItem.parentId !== null) {
      if (propertyItem.index !== null) {
        currentProps[propertyItem.parentId].items![propertyItem.index] = {
          en: { prop: propertyItem.en, value: propertyItem.desc_en },
          fa: { prop: propertyItem.fa, value: propertyItem.desc_fa },
        };
      } else {
        currentProps[propertyItem.parentId].items!.push({
          en: { prop: propertyItem.en, value: propertyItem.desc_en },
          fa: { prop: propertyItem.fa, value: propertyItem.desc_fa },
        });
      }
      form.setValue("properties", currentProps);
      setPropertyItem(getPropertyItemDefaultValue());
      setOpenDialog(false);
    }
  };

  const category = form.watch("category");
  const subcategory = form.watch("subcategory");
  const brand = form.watch("brand");
  const isOnSale = form.watch("price.sale.is_on_sale");
  const isUsed = form.watch("price.is_used");

  const getCurrentDialogContent = (section: "newGroup" | "newItem" | "newFeature" | null) => {
    switch (section) {
      case "newGroup":
        return (
          <>
            <DialogHeader>
              <DialogTitle>
                <Trans i18nKey="products.addNewGroup" />
              </DialogTitle>
            </DialogHeader>
            {/* new group info */}
            <div className="space-y-4">
              {/* name fa */}
              <div>
                <Label>
                  <Trans i18nKey="products.nameFa" />
                </Label>
                <Input
                  placeholder={t("products.nameFa")}
                  name="fa"
                  value={newGroupInfo.fa}
                  onChange={(e) =>
                    setNewGroupInfo({
                      ...newGroupInfo,
                      fa: e.target.value,
                    })
                  }
                />
              </div>

              {/* name en */}
              <div>
                <Label>
                  <Trans i18nKey="products.nameEn" />
                </Label>
                <Input
                  name="en"
                  value={newGroupInfo.en}
                  className="text-left dir-ltr placeholder:text-right placeholder:dir-rtl placeholder:font-dana font-montserrat"
                  placeholder={t("products.nameEn")}
                  onChange={(e) =>
                    setNewGroupInfo({
                      ...newGroupInfo,
                      en: e.target.value,
                    })
                  }
                />
              </div>
              {/* icon */}
              <div>
                <Label>
                  <Trans i18nKey="products.icon" />
                </Label>
                <Select
                  name="icon"
                  disabled={!hasPermissions(profile, [ADMIN_PERMISSIONS.WRITE_PRODUCT])}
                  value={newGroupInfo.icon}
                  onValueChange={(e) => setNewGroupInfo({ ...newGroupInfo, icon: e })}
                >
                  <SelectTrigger>
                    <SelectValue placeholder={t("products.icon")} />
                  </SelectTrigger>
                  <SelectContent>
                    {getAvailableGroups(form.getValues("properties") ?? []).map((icon) => (
                      <SelectItem key={icon.id} value={icon.id.toString()}>
                        <div className="flex items-center gap-2">{icon.el}</div>
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>
              <div className="flex justify-end gap-2">
                <DialogClose asChild>
                  <Button
                    variant="secondary"
                    onClick={() => {
                      setNewGroupInfo({ fa: "", en: "", icon: "" });
                      setDialogContentEl(null);
                    }}
                  >
                    <Trans i18nKey="common.cancel" />
                  </Button>
                </DialogClose>

                <Button
                  onClick={() => {
                    form.setValue("properties", [
                      ...(form.getValues("properties") ?? []),
                      {
                        title: { fa: newGroupInfo.fa, en: newGroupInfo.en },
                        icon: newGroupInfo.icon,
                        items: [],
                      },
                    ]);
                    setNewGroupInfo({ fa: "", en: "", icon: "" });
                    setOpenDialog(false);
                  }}
                >
                  <Trans i18nKey="common.submit" />
                </Button>
              </div>
            </div>
          </>
        );
      case "newItem":
        return (
          <>
            <DialogHeader>
              <DialogTitle>{propertyItem.dialogTitle}</DialogTitle>
            </DialogHeader>
            {/* body */}
            <div className="grid grid-cols-2 gap-2">
              {/* name fa */}
              <div>
                <Label>
                  <Trans i18nKey="products.nameFa" />
                </Label>
                <Input
                  placeholder={t("products.nameFa")}
                  name="fa"
                  value={propertyItem.fa}
                  onChange={(e) =>
                    setPropertyItem((prev) => ({
                      ...prev,
                      fa: e.target.value,
                    }))
                  }
                />
              </div>

              {/* name en */}
              <div>
                <Label>
                  <Trans i18nKey="products.nameEn" />
                </Label>
                <Input
                  name="en"
                  value={propertyItem.en}
                  className="text-left dir-ltr placeholder:text-right placeholder:dir-rtl placeholder:font-dana font-montserrat"
                  placeholder={t("products.nameEn")}
                  onChange={(e) =>
                    setPropertyItem((prev) => ({
                      ...prev,
                      en: e.target.value,
                    }))
                  }
                />
              </div>

              {/* desc fa */}
              <div>
                <Label>
                  <Trans i18nKey="products.descFa" />
                </Label>
                <Input
                  placeholder={t("products.descFa")}
                  name="desc_fa"
                  value={propertyItem.desc_fa}
                  onChange={(e) =>
                    setPropertyItem((prev) => ({
                      ...prev,
                      desc_fa: e.target.value,
                    }))
                  }
                />
              </div>

              {/* desc en */}
              <div>
                <Label>
                  <Trans i18nKey="products.descEn" />
                </Label>
                <Input
                  name="desc_en"
                  value={propertyItem.desc_en}
                  className="text-left dir-ltr placeholder:text-right placeholder:dir-rtl placeholder:font-dana font-montserrat"
                  placeholder={t("products.descEn")}
                  onChange={(e) =>
                    setPropertyItem((prev) => ({
                      ...prev,
                      desc_en: e.target.value,
                    }))
                  }
                />
              </div>
              <div className="col-span-2 flex items-center justify-end gap-2">
                <DialogClose asChild>
                  <Button
                    variant="secondary"
                    onClick={() => {
                      setPropertyItem(getPropertyItemDefaultValue());
                      setDialogContentEl(null);
                    }}
                  >
                    <Trans i18nKey="common.cancel" />
                  </Button>
                </DialogClose>

                <Button onClick={onEditPropertyItem}>
                  <Trans i18nKey="common.submit" />
                </Button>
              </div>
            </div>
          </>
        );
      case "newFeature":
        return (
          <>
            <DialogHeader>
              <DialogTitle>{featureItem.dialogTitle}</DialogTitle>
            </DialogHeader>
            {/* new group info */}
            <div className="space-y-4">
              {/* desc fa */}
              <div>
                <Label>
                  <Trans i18nKey="products.descFa" />
                </Label>
                <Input
                  placeholder={t("products.descFa")}
                  name="fa"
                  value={featureItem.fa}
                  onChange={(e) =>
                    setFeatureItem((prev) => ({
                      ...prev,
                      fa: e.target.value,
                    }))
                  }
                />
              </div>

              {/* desc en */}
              <div>
                <Label>
                  <Trans i18nKey="products.descEn" />
                </Label>
                <Input
                  name="en"
                  value={featureItem.en}
                  className="text-left dir-ltr placeholder:text-right placeholder:dir-rtl placeholder:font-dana font-montserrat"
                  placeholder={t("products.descEn")}
                  onChange={(e) =>
                    setFeatureItem((prev) => ({
                      ...prev,
                      en: e.target.value,
                    }))
                  }
                />
              </div>

              <div className="flex justify-end gap-2">
                <DialogClose asChild>
                  <Button
                    variant="secondary"
                    onClick={() => {
                      setFeatureItem({ fa: "", en: "", dialogTitle: "", index: null });
                      setDialogContentEl(null);
                    }}
                  >
                    <Trans i18nKey="common.cancel" />
                  </Button>
                </DialogClose>

                <Button onClick={onEditFeature}>
                  <Trans i18nKey="common.submit" />
                </Button>
              </div>
            </div>
          </>
        );
      default:
        return <React.Fragment></React.Fragment>;
    }
  };

  const onDuplicate = (query = "") => {
    if (product?.id) {
      navigate(`/products/add/${product.id}` + query);
      toast({
        description: t("products.duplicated"),
      });
    }
  };

  const onDelete = async () => {
    if (product?.id) {
      try {
        setDeleting(true);
        const resp = await ProductAPI.deleteById(product.id);
        navigate(-1);
        toast({ description: resp.data.data.message.fa });
      } catch (err) {
        toast({ description: (err as Error).message, variant: "destructive" });
      } finally {
        setDeleting(false);
      }
    }
  };

  function changeToUsed() {
    if (product?.id) {
      onDuplicate("?isUsed=true");
    } else {
      form.setValue("price.is_used", true);
    }
  }

  React.useEffect(() => {
    const isUsedDuplicate = searchParams.get("isUsed") === "true";
    if (isUsedDuplicate) {
      form.setValue("price.is_used", true);
    }
  }, [searchParams]);

  React.useEffect(() => {
    if (category) {
      SubcategoryAPI.getListByCategoryId(category)
        .then((resp) => {
          setSubcategroyList(resp.data.data.items);
        })
        .catch(() => {
          setSubcategroyList([]);
        });
    }
  }, [category]);

  React.useEffect(() => {
    if (brand) {
      ModelAPI.getList({ brand_id: brand })
        .then((resp) => {
          setModelsList(resp.data.data.items);
        })
        .catch(() => {
          setModelsList([]);
        });
    }
  }, [brand]);

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit, onError)} className="flex-1 flex flex-col">
        {activeErrors.length ? (
          <div className="p-4 bg-destructive/10 border border-destructive text-destructive text-sm grid grid-cols-4 gap-2 mb-4">
            {activeErrors.map((text) => (
              <p key={uuidv4()}>- {text}</p>
            ))}
          </div>
        ) : null}
        <Button disabled={isUsed} className="w-[9rem] mb-4" onClick={changeToUsed}>
          <Trans i18nKey={isUsed ? "products.used" : "products.changeToUsed"} />
        </Button>
        <Tabs defaultValue="information" className="flex-1">
          <TabsList className={cn("grid w-full", isUsed ? "grid-cols-5" : "grid-cols-4")}>
            <TabsTrigger value="information">
              <Trans i18nKey="products.informationSection" />
            </TabsTrigger>
            <TabsTrigger value="price">
              <Trans i18nKey="products.priceSection" />
            </TabsTrigger>
            <TabsTrigger value="gallery">
              <Trans i18nKey="products.gallerySection" />
            </TabsTrigger>
            <TabsTrigger value="properties">
              <Trans i18nKey="products.propertiesSection" />
            </TabsTrigger>
            {isUsed && (
              <TabsTrigger value="health">
                <Trans i18nKey="products.healthSection" />
              </TabsTrigger>
            )}
          </TabsList>
          <TabsContent value="information">
            <div className="bg-gray-scale-5 p-4 grid grid-cols-2 gap-2">
              {/* Brand */}
              <FormField
                control={form.control}
                name="brand"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      <Trans i18nKey="products.brand" />
                    </FormLabel>
                    <Select
                      disabled={!hasPermissions(profile, [ADMIN_PERMISSIONS.WRITE_PRODUCT])}
                      onValueChange={field.onChange}
                      defaultValue={field.value}
                    >
                      <FormControl>
                        <SelectTrigger>
                          <SelectValue placeholder={t("products.selectBrand")} />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        {brands.map((brand) => (
                          <SelectItem className="dir-ltr" key={brand.id} value={brand.id}>
                            <div className="flex items-center gap-1">
                              <img src={brand.logo} className="h-8 w-9" alt={brand.name.en} />
                              <span className="font-montserrat">{brand.name.en}</span>
                            </div>
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>

                    <FormMessage />
                  </FormItem>
                )}
              />

              {/* Model */}
              <FormField
                control={form.control}
                name="model"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      <Trans i18nKey="products.model" />
                    </FormLabel>
                    <Select
                      disabled={!hasPermissions(profile, [ADMIN_PERMISSIONS.WRITE_PRODUCT])}
                      onValueChange={field.onChange}
                      defaultValue={field.value}
                    >
                      <FormControl>
                        <SelectTrigger disabled={modelsList.length === 0}>
                          <SelectValue placeholder={t("products.selectModel")} />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        {modelsList.map((model) => (
                          <SelectItem className="font-montserrat dir-ltr" key={model.id} value={model.id}>
                            {model.name}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>

                    <FormMessage />
                  </FormItem>
                )}
              />

              {/* Name */}
              <FormField
                control={form.control}
                name="name"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      <Trans i18nKey="products.name" />
                    </FormLabel>

                    <FormControl>
                      <Input
                        disabled={!hasPermissions(profile, [ADMIN_PERMISSIONS.WRITE_PRODUCT])}
                        placeholder={t("products.name")}
                        {...field}
                      />
                    </FormControl>

                    <FormMessage />
                  </FormItem>
                )}
              />

              {/* Color */}
              <FormField
                control={form.control}
                name="color"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      <Trans i18nKey="products.color" />
                    </FormLabel>

                    <FormControl>
                      <ColorPicker
                        disabled={!hasPermissions(profile, [ADMIN_PERMISSIONS.WRITE_PRODUCT])}
                        list={colors}
                        value={field.value}
                        onChange={field.onChange}
                      />
                    </FormControl>

                    {/* <FormMessage /> */}
                  </FormItem>
                )}
              />

              {/* Category */}
              <FormField
                control={form.control}
                name="category"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      <Trans i18nKey="products.category" />
                    </FormLabel>
                    <Select
                      disabled={!hasPermissions(profile, [ADMIN_PERMISSIONS.WRITE_PRODUCT])}
                      onValueChange={field.onChange}
                      defaultValue={field.value}
                    >
                      <FormControl>
                        <SelectTrigger>
                          <SelectValue placeholder={t("products.category")} />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        {categories.map((category) => (
                          <SelectItem key={category.id} value={category.id}>
                            {category.title.fa}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>

                    <FormMessage />
                  </FormItem>
                )}
              />

              {/* SubCategory */}
              <FormField
                control={form.control}
                name="subcategory"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      <Trans i18nKey="products.subcategory" />
                    </FormLabel>
                    <Select
                      disabled={!hasPermissions(profile, [ADMIN_PERMISSIONS.WRITE_PRODUCT])}
                      onValueChange={(value) => {
                        field.onChange(value);
                        form.setValue("tags", []);
                      }}
                      defaultValue={field.value}
                    >
                      <FormControl>
                        <SelectTrigger disabled={subcategroyList.length === 0}>
                          <SelectValue placeholder={t("products.subcategory")} />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        {subcategroyList.map((subcategory) => (
                          <SelectItem key={subcategory.id} value={subcategory.id}>
                            {subcategory.title.fa}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>

                    <FormMessage />
                  </FormItem>
                )}
              />

              {/* Tags */}
              <FormField
                control={form.control}
                name="tags"
                render={({ field }) => (
                  <FormItem className="col-span-2">
                    <FormLabel>
                      <Trans i18nKey="products.tags" />
                    </FormLabel>
                    <FormControl>
                      <MultiSelect
                        noSearch
                        placeholder={t("common.select")}
                        onChange={field.onChange}
                        selected={field.value}
                        options={
                          tags
                            .filter((tag) => tag.subcategory.id === subcategory)
                            .map((i) => ({ value: i.id, label: i.title.fa })) ?? []
                        }
                        disabled={!hasPermissions(profile, [ADMIN_PERMISSIONS.WRITE_PRODUCT])}
                      />
                    </FormControl>

                    <FormMessage />
                  </FormItem>
                )}
              />

              {/* Seo Fa Name */}
              <FormField
                control={form.control}
                name="seo_name_fa"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      <Trans i18nKey="products.seoNameFa" />
                    </FormLabel>

                    <FormControl>
                      <Input
                        disabled={!hasPermissions(profile, [ADMIN_PERMISSIONS.WRITE_PRODUCT])}
                        placeholder={t("products.seoNameFa")}
                        {...field}
                      />
                    </FormControl>

                    <FormMessage />
                  </FormItem>
                )}
              />

              {/* Seo En Name */}
              <FormField
                control={form.control}
                name="seo_name_en"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      <Trans i18nKey="products.seoNameEn" />
                    </FormLabel>

                    <FormControl>
                      <Input
                        disabled={!hasPermissions(profile, [ADMIN_PERMISSIONS.WRITE_PRODUCT])}
                        className="text-left dir-ltr placeholder:text-right placeholder:dir-rtl placeholder:font-dana font-montserrat"
                        placeholder={t("products.seoNameEn")}
                        {...field}
                      />
                    </FormControl>

                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
          </TabsContent>
          <TabsContent value="price">
            <div className="bg-gray-scale-5 p-4 space-y-6">
              <div className="grid grid-cols-4 gap-2">
                {/* Price */}
                <FormField
                  control={form.control}
                  name="price.price"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>
                        <Trans i18nKey="products.price" />
                      </FormLabel>

                      <FormControl>
                        <Input
                          placeholder={t("products.price")}
                          {...field}
                          className="text-left dir-ltr placeholder:text-right placeholder:dir-rtl placeholder:font-dana font-montserrat"
                          value={addCommas(field.value)}
                          onChange={(e) => {
                            field.onChange(e.target.value.replace(/,/g, ""));
                          }}
                          disabled={!hasPermissions(profile, [ADMIN_PERMISSIONS.WRITE_PRODUCT])}
                        />
                      </FormControl>

                      <FormMessage />
                    </FormItem>
                  )}
                />

                {/* Count */}
                <FormField
                  control={form.control}
                  name="availability.total_count"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>
                        <Trans i18nKey="products.count" />
                      </FormLabel>

                      <FormControl>
                        <Input
                          placeholder={t("products.count")}
                          {...field}
                          className="text-left dir-ltr placeholder:text-right placeholder:dir-rtl placeholder:font-dana font-montserrat"
                          value={addCommas(field.value)}
                          onChange={(e) => {
                            field.onChange(e.target.value.replace(/,/g, ""));
                          }}
                          disabled={!hasPermissions(profile, [ADMIN_PERMISSIONS.WRITE_PRODUCT])}
                        />
                      </FormControl>

                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              <div className="flex flex-col gap-4">
                {/* call to buy */}
                <FormField
                  control={form.control}
                  name="price.call_to_buy"
                  render={({ field }) => (
                    <FormItem className="flex items-center gap-2 space-y-0">
                      <FormControl>
                        <Checkbox
                          disabled={!hasPermissions(profile, [ADMIN_PERMISSIONS.WRITE_PRODUCT])}
                          checked={field.value}
                          onCheckedChange={field.onChange}
                        />
                      </FormControl>
                      <FormLabel>
                        <Trans i18nKey="products.callToBuy" />
                      </FormLabel>

                      <FormMessage />
                    </FormItem>
                  )}
                />

                {/* is used */}
                <FormField
                  control={form.control}
                  name="price.is_used"
                  render={({ field }) => (
                    <FormItem className="flex items-center gap-2 space-y-0">
                      <FormControl>
                        <Checkbox
                          disabled={!hasPermissions(profile, [ADMIN_PERMISSIONS.WRITE_PRODUCT])}
                          checked={field.value}
                          onCheckedChange={field.onChange}
                        />
                      </FormControl>
                      <FormLabel>
                        <Trans i18nKey="products.isUsed" />
                      </FormLabel>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                {/* is on sale */}
                <FormField
                  control={form.control}
                  name="price.sale.is_on_sale"
                  render={({ field }) => (
                    <FormItem className="flex items-center gap-2 space-y-0">
                      <FormControl>
                        <Checkbox
                          disabled={!hasPermissions(profile, [ADMIN_PERMISSIONS.WRITE_PRODUCT])}
                          checked={field.value}
                          onCheckedChange={field.onChange}
                        />
                      </FormControl>
                      <FormLabel>
                        <Trans i18nKey="products.isOnSale" />
                      </FormLabel>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              <div className="grid grid-cols-4 gap-2">
                {isOnSale ? (
                  <FormField
                    control={form.control}
                    name="price.sale.off_percent"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>
                          <Trans i18nKey="products.offPercent" />
                        </FormLabel>

                        <FormControl>
                          <Input
                            placeholder={t("products.offPercent")}
                            {...field}
                            className="text-left dir-ltr placeholder:text-right placeholder:dir-rtl placeholder:font-dana font-montserrat"
                            value={addCommas(field.value ?? "")}
                            onChange={(e) => {
                              field.onChange(e.target.value.replace(/,/g, ""));
                            }}
                            disabled={!hasPermissions(profile, [ADMIN_PERMISSIONS.WRITE_PRODUCT])}
                          />
                        </FormControl>

                        <FormMessage />
                      </FormItem>
                    )}
                  />
                ) : null}
              </div>
            </div>
          </TabsContent>
          <TabsContent value="gallery" className="bg-gray-scale-5 p-4">
            <div className="mb-4">
              <Label className="flex items-center gap-2">
                <Trans i18nKey="products.watermark" />
                <Switch
                  disabled={!hasPermissions(profile, [ADMIN_PERMISSIONS.WRITE_PRODUCT])}
                  checked={hasWatermark}
                  onCheckedChange={setHasWatermark}
                />
              </Label>
            </div>
            <FormField
              control={form.control}
              name="images"
              render={({ field }) => (
                <FormItem className="grid grid-cols-4 gap-2 space-y-0">
                  {field.value?.map((image) => (
                    <ImageGallery
                      watermark={hasWatermark}
                      key={image.url}
                      value={image}
                      disabled={!hasPermissions(profile, [ADMIN_PERMISSIONS.WRITE_PRODUCT])}
                      onChange={(item) => {
                        field.onChange(
                          field.value?.map((i) => {
                            if (i.url === item.url || i.thumbnail === item.thumbnail) {
                              return item;
                            }
                            return i;
                          }),
                        );
                      }}
                      onDelete={(url) => {
                        field.onChange(field.value?.filter((i) => i.url !== url));
                      }}
                      onTogglePrimary={(url, state) => {
                        field.onChange(
                          field.value?.map((i) => {
                            if (i.url === url) {
                              return { ...i, is_primary: state };
                            }
                            return { ...i, is_primary: false };
                          }),
                        );
                      }}
                    />
                  ))}
                  <FormControl>
                    {hasPermissions(profile, [ADMIN_PERMISSIONS.WRITE_PRODUCT]) ? (
                      <ImageGallery
                        watermark={hasWatermark}
                        onChange={(item) => field.onChange([...(field.value ?? []), item])}
                      />
                    ) : null}
                  </FormControl>
                  <FormMessage className="col-span-4" />
                </FormItem>
              )}
            />
          </TabsContent>
          <FormProvider {...form}>{isUsed && <HealthTabContent id={product?.inspection_id} />}</FormProvider>
          <TabsContent value="properties">
            {/* Dialog */}
            <Dialog open={openDialog} onOpenChange={setOpenDialog}>
              <DialogContent>{getCurrentDialogContent(dialogContentEl)}</DialogContent>
              <div className="bg-gray-scale-5 p-4 space-y-4">
                {/* Description */}
                <div className="flex flex-col gap-1 col-span-4">
                  <span>
                    <Trans i18nKey="categories.desc" />
                  </span>
                  <Tabs defaultValue="fa" className="w-full">
                    <TabsList className="w-full grid grid-cols-2">
                      <TabsTrigger value="en">
                        <Trans i18nKey="categories.descEn" />
                      </TabsTrigger>
                      <TabsTrigger value="fa">
                        <Trans i18nKey="categories.descFa" />
                      </TabsTrigger>
                    </TabsList>
                    <TabsContent value="fa">
                      <FormField
                        control={form.control}
                        name="description.fa"
                        render={({ field }) => (
                          <FormItem className="col-span-4">
                            <FormControl>
                              <Editor data={field.value} onChange={field.onChange} />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                    </TabsContent>
                    <TabsContent value="en">
                      <FormField
                        control={form.control}
                        name="description.en"
                        render={({ field }) => (
                          <FormItem className="col-span-4">
                            <FormControl>
                              <Editor en data={field.value} onChange={field.onChange} />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                    </TabsContent>
                  </Tabs>
                </div>

                {/* Features */}
                <div>
                  {/* section header */}
                  <div className="flex items-center justify-between">
                    <span>
                      <Trans i18nKey="products.features" />
                    </span>

                    <Button
                      className={cn({ hidden: !hasPermissions(profile, [ADMIN_PERMISSIONS.WRITE_PRODUCT]) })}
                      onClick={() => {
                        setDialogContentEl("newFeature");
                        setOpenDialog(true);
                        setFeatureItem((prev) => ({ ...prev, dialogTitle: t("products.addNewFeature") }));
                      }}
                    >
                      <Trans i18nKey="products.addNewFeature" />
                    </Button>
                  </div>

                  {/* section body */}
                  <FormField
                    control={form.control}
                    name="features"
                    render={({ field }) => (
                      <div className="mt-4">
                        <FormItem>
                          <FormControl>
                            {field.value?.length ? (
                              <>
                                {field.value?.map((feature, groupIndex) => (
                                  <div key={uuidv4()} className="bg-gray-scale-6 p-4">
                                    <div className="flex items-center justify-between">
                                      <div className="flex flex-col gap-2">
                                        <span className="text-gray-scale-3 text-sm">{feature.fa}</span>
                                        <span className="text-gray-scale-3 text-sm dir-ltr text-left font-montserrat">
                                          {feature.en}
                                        </span>
                                      </div>
                                      <div
                                        className={cn("flex items-center gap-2", {
                                          hidden: !hasPermissions(profile, [ADMIN_PERMISSIONS.WRITE_PRODUCT]),
                                        })}
                                      >
                                        <Button
                                          variant="ghost"
                                          size="icon"
                                          onClick={() => {
                                            setDialogContentEl("newFeature");
                                            setFeatureItem({
                                              fa: field.value![groupIndex].fa ?? "",
                                              en: field.value![groupIndex].en ?? "",
                                              dialogTitle: t("products.editFeature"),
                                              index: groupIndex,
                                            });
                                            setOpenDialog(true);
                                          }}
                                        >
                                          <Pencil size={18} />
                                        </Button>
                                        <Button
                                          variant="ghost-destructive"
                                          size="icon"
                                          onClick={() =>
                                            field.onChange(field.value?.filter((_i, idx) => idx !== groupIndex))
                                          }
                                        >
                                          <Trash2 size={20} />
                                        </Button>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </>
                            ) : (
                              <div className="text-gray-scale-4 text-center bg-gray-scale-6 p-4">
                                <Trans i18nKey="common.isEmpty" />
                              </div>
                            )}
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      </div>
                    )}
                  />
                </div>

                {/* Properties */}
                <div>
                  {/* section header */}
                  <div className="flex items-center justify-between">
                    <span>
                      <Trans i18nKey="products.properties" />
                    </span>
                    <Button
                      disabled={getAvailableGroups(form.getValues("properties") ?? []).length === 0}
                      onClick={() => {
                        setDialogContentEl("newGroup");
                        setOpenDialog(true);
                      }}
                    >
                      <Trans i18nKey="products.addNewGroup" />
                    </Button>
                  </div>

                  {/* section body */}
                  <FormField
                    control={form.control}
                    name="properties"
                    render={({ field }) => (
                      <div className="mt-4">
                        <FormItem>
                          <FormControl>
                            <>
                              {field.value?.length ? (
                                <>
                                  {/* property group starts */}
                                  {field.value.map((property, groupIndex) => (
                                    <div key={uuidv4()} className="bg-gray-scale-6 p-4">
                                      {/* property header starts */}
                                      <div className="flex items-center justify-between">
                                        <div className="flex items-center gap-2">
                                          {icons[+property.icon]?.el}
                                          <span className="text-gray-scale-3 text-sm">{property.title.fa}</span>
                                        </div>
                                        <div>
                                          <Button
                                            size="sm"
                                            onClick={() => {
                                              setOpenDialog(true);
                                              setDialogContentEl("newItem");
                                              setPropertyItem((prev) => ({
                                                ...prev,
                                                parentId: groupIndex,
                                              }));
                                            }}
                                          >
                                            <Trans i18nKey="products.addNewSpec" />
                                          </Button>
                                          <Button
                                            variant="secondary"
                                            size="sm"
                                            onClick={() =>
                                              field.onChange(field.value?.filter((_i, idx) => idx !== groupIndex))
                                            }
                                          >
                                            <Trans i18nKey="common.delete" />
                                          </Button>
                                        </div>
                                      </div>
                                      <div className={cn("grid grid-cols-3 gap-2", { "mt-4": property.items?.length })}>
                                        {/* property item starts */}
                                        {property.items?.map((item, itemIndex) => (
                                          <div className="flex bg-gray-scale-5" key={uuidv4()}>
                                            <div className="flex-1 p-2 space-y-2">
                                              <div className="text-sm flex items-center justify-between border-b border-white/10 pb-2">
                                                <span className="text-gray-scale-4">{item.fa.prop}:</span>
                                                <span className="font-montserrat text-left dir-ltr">
                                                  {item.fa.value}
                                                </span>
                                              </div>
                                              <div className="text-sm flex items-center justify-between dir-ltr text-left font-montserrat">
                                                <span className="text-gray-scale-4">:{item.en.prop}</span>
                                                <span>{item.en.value}</span>
                                              </div>
                                            </div>
                                            <div className="flex flex-col">
                                              <Button
                                                variant="ghost"
                                                size="icon"
                                                onClick={() => {
                                                  setDialogContentEl("newItem");
                                                  setOpenDialog(true);
                                                  setPropertyItem({
                                                    desc_en: item.en.value,
                                                    desc_fa: item.fa.value,
                                                    fa: item.fa.prop,
                                                    en: item.en.prop,
                                                    parentId: groupIndex,
                                                    index: itemIndex,
                                                    dialogTitle: t("products.editSpec"),
                                                  });
                                                }}
                                              >
                                                <Pencil size={14} />
                                              </Button>

                                              <Button
                                                variant="ghost-destructive"
                                                size="icon"
                                                onClick={() => {
                                                  const groups = field.value ?? [];
                                                  const updatedGroup = groups[groupIndex].items?.filter(
                                                    (_i, idx) => idx !== itemIndex,
                                                  );
                                                  groups[groupIndex].items = updatedGroup;
                                                  field.onChange(groups);
                                                }}
                                              >
                                                <Trash2 size={16} />
                                              </Button>
                                            </div>
                                          </div>
                                        ))}
                                        {/* property item ends */}
                                      </div>
                                    </div>
                                  ))}
                                  {/* property group ends */}
                                </>
                              ) : (
                                <div className="text-gray-scale-4 text-center bg-gray-scale-6 p-4">
                                  <Trans i18nKey="common.isEmpty" />
                                </div>
                              )}
                            </>
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      </div>
                    )}
                  />
                </div>
              </div>
            </Dialog>
          </TabsContent>
        </Tabs>

        {/* CTAs */}
        <div
          className={cn("flex items-center justify-end gap-2 mt-4", {
            hidden: !hasPermissions(profile, [ADMIN_PERMISSIONS.WRITE_PRODUCT]),
          })}
        >
          {params.id ? (
            <div className="flex-1 flex items-center gap-4">
              <Button variant="secondary" onClick={() => onDuplicate()}>
                <Trans i18nKey="products.duplicate" />
              </Button>
              <Button variant="destructive" onClick={() => setOpenDeleteDialog(true)}>
                <Trans i18nKey="products.delete" />
              </Button>

              <AlertDialog open={openDeleteDialog} onOpenChange={setOpenDeleteDialog}>
                <AlertDialogContent>
                  <AlertDialogHeader>
                    <AlertDialogTitle>
                      <Trans i18nKey="products.deleteSure" />
                    </AlertDialogTitle>
                  </AlertDialogHeader>
                  <AlertDialogFooter className="gap-4">
                    <AlertDialogCancel>
                      <Trans i18nKey="common.cancel" />
                    </AlertDialogCancel>
                    <Button onClick={onDelete} loading={deleting} variant="destructive">
                      <Trans i18nKey="common.delete" />
                    </Button>
                  </AlertDialogFooter>
                </AlertDialogContent>
              </AlertDialog>
            </div>
          ) : null}

          {/* Validate */}
          <Button
            variant="ghost"
            onClick={() => {
              const validationRes = onValidate(form.getValues());
              if (!validationRes) {
                toast({ description: t("products.errors.noError") });
                setActiveErrors([]);
                return;
              }
              toast({ variant: "destructive", description: t("products.errors.hasError") });
              setActiveErrors(validationRes);
            }}
          >
            <Trans i18nKey="products.validate" />
          </Button>

          {/* Draft */}
          <Button
            loading={loading.draft}
            variant="secondary"
            onClick={async () => {
              setLoading((prev) => ({ ...prev, draft: true }));
              const validationRes = onDraft(form.getValues());
              if (Array.isArray(validationRes)) {
                toast({ variant: "destructive", description: t("products.errors.hasError") });
                setActiveErrors(validationRes);
                setLoading((prev) => ({ ...prev, draft: false }));
                return;
              }
              setActiveErrors([]);
              await validationRes();
              setLoading((prev) => ({ ...prev, draft: false }));
            }}
          >
            <Trans i18nKey="products.draft" />
          </Button>

          {/* Publish */}
          <Button
            loading={loading.publish}
            onClick={async () => {
              setLoading((prev) => ({ ...prev, publish: true }));
              const validationRes = onPublish(form.getValues());
              if (Array.isArray(validationRes)) {
                toast({ variant: "destructive", description: t("products.errors.hasError") });
                setActiveErrors(validationRes);
                setLoading((prev) => ({ ...prev, publish: false }));
                return;
              }
              setActiveErrors([]);
              await validationRes();
              setLoading((prev) => ({ ...prev, publish: false }));
            }}
          >
            <Trans i18nKey="products.publish" />
          </Button>
        </div>
      </form>
    </Form>
  );
}
